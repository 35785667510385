@import 'src/assets/scss/_variables.scss';

/* Intro.js overrides */
.introjs-tooltipReferenceLayer {
  font-family: $font-family;
  .introjs-tooltip {
    max-width: 650px;

    .introjs-tooltipbuttons {
      .introjs-button {
        font-weight: 400;
        border-radius: 7px;
        border-color: transparent;
      }

      .introjs-nextbutton {
        background-color: $primary;
        color: white;
        text-shadow: none;
      }
    }
  }
}
