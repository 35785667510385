/* You can add global styles to this file, and also import other style files */
@import 'ngx-toastr/toastr';
@import '../node_modules/ngx-red-zoom/styles/base.scss';
@import '../node_modules/ngx-red-zoom/styles/style-window.scss';

*::-webkit-scrollbar {
  display: none !important;
}

html {
  scroll-behavior: smooth;
  overflow-y: hidden;
}

.row-justify-center {
  justify-content: center;
}

.hide-badge-content {
  .mat-badge-content {
    color: transparent;
  }
}
